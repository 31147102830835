<template>

  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <b-img :src="logo_img" width="60" />
        </h2>
      </b-link>

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
        <template v-if="!isResetPassword">
        <template v-if="!hasStores">
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-primary"
          >
            {{$t('Welcome to Mhd')}}! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{$t('Please sign-in to your account and start manage your e-commerce')}}
          </b-card-text>

          <b-alert
              variant="danger"
              show
              v-if="showError"
            >
              <h4 class="alert-heading">
                {{$t('Oops')}}!
              </h4>
              <div class="alert-body" v-if="errors">
                <span v-for="error in errors">{{error[0]}}</span>
              </div>
            </b-alert>
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="submit"
            >
              <b-form-group
                :label="$t('Email')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="store@mhd.sa"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{$t('Password')}}</label>
                  <b-link @click="isResetPassword = !isResetPassword">
                    <small>{{$t('Forgot Password?')}}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      type="password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="············"
                    />

                  </b-input-group>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  name="checkbox-1"
                >
                  {{$t('Remember Me')}}
                </b-form-checkbox>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
              >
                <template v-if="!isLoading">
                  {{$t('Sign in')}}
                </template>
                <template v-if="isLoading">
                  {{$t('Signing in')}}...
                  <b-spinner small />
                </template>
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-2">
            <span>{{$t('New on our platform?')}} </span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;{{$t('Create an store')}}</span>
            </b-link>
          </b-card-text>
        </template>
        <template v-else>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-primary"
          >
            {{$t('Select Store')}}
          </b-card-title>

          <b-row class="match-height">
            <b-col
              cols="6"
              sm="6"
              class="text-center cursor-pointer"
              @click="selectStore(loginResponse.store)"
              v-if="loginResponse.store"
              >
              <b-img-lazy :src="loginResponse.store.logo || logo_img" thumbnail fluid />
              <p>{{loginResponse.store.name}}</p>
            </b-col>
            <b-col
              cols="6"
              sm="6"
              class="text-center cursor-pointer"
              v-for="(store, index) in this.loginResponse.stores"
              :key="index"
              @click="selectStore(store)"
              >
              <b-img-lazy :src="store.logo || logo_img" thumbnail fluid />
              <p>{{store.name}}</p>
            </b-col>
          </b-row>
        </template>
        </template>
        <template v-else>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-primary"
          >
            {{$t('Forgot Password?')}} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{$t("Enter your email and we'll send you instructions to reset your password")}}
          </b-card-text>

          <validation-observer ref="resetPasswordValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="resetPasswordSubmit"
            >
              <b-form-group
                :label="$t('Email')"
                label-for="reset-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="resetPassword.email"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-email"
                    placeholder="store@mhd.sa"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                <template v-if="!isLoading">
                  {{$t('Send')}}
                </template>
                <template v-if="isLoading">
                  {{$t('Sending')}}...
                  <b-spinner small />
                </template>
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <span>&nbsp;{{$t('Sign in')}}</span>
            </b-link>
          </b-card-text>
        </template>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email } from '@validations'
import {mapActions,mapGetters} from 'vuex'
import useJwt from '@/auth/useJwt'
import store from '@/store/'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  name:'Login',
  mounted() {
    localize('ar',{
      messages: {
        required: 'هذا الحقل مطلوب',
        email: 'يرجى إدخال بريد إلكتروني صحيح',
      },
    })
    this.$i18n.locale = 'ar'
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v22.png'),
      form:{
        email:'',
        password:''
      },
      resetPassword:{
        email:''
      },
      showError:false,
      isLoading: false,
      hasStores: false,
      isResetPassword: false,
      loginResponse: {},
      logo_img: require('@/assets/images/logo/logo.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v22.png')
        return this.sideImg
      }
      return this.sideImg
    },
    ...mapGetters({errors:"getError"}),
  },
  methods: {
    ...mapActions(["login"]),

    submit: function () {
      this.isLoading = true;
      // this.$store.dispatch('login', this.form)
      useJwt.post('/login',this.form)
      .then(response => {
        this.loginResponse = response.data.data
        console.log(this.loginResponse.user.permissions)
        if (Object.keys(this.loginResponse.stores).length) {
          this.hasStores = true;
          return;
        }
        useJwt.setToken(this.loginResponse.token)
        useJwt.setUserData(JSON.stringify(this.loginResponse.user))
        useJwt.setStoreData(JSON.stringify(this.loginResponse.store))
        window.location.href = '/';
      })
      .catch(error => {
        this.isLoading = false;
        let error_msg = ''
        console.log('error')
        console.log(error.response)
        if(error.response.data.message.email != undefined) {
            if (error_msg != '') {
                error_msg+='\n'+error.response.data.message.email
            } else {
                error_msg+=error.response.data.message.email
            }
        }
        if(error.response.data.message.password != undefined) {
            if (error_msg != '') {
                error_msg+='\n'+error.response.data.message.password
            } else {
                error_msg+=error.response.data.message.password
            }
        }
        if(error.response.data.code == 422) {
            if (error_msg != '') {
                error_msg+='\n'+error.response.data.message
            } else {
                error_msg+=error.response.data.message
            }
        }
        this.$root.showAlert('error', error_msg)
      })
    },
    selectStore(store) {
      useJwt.setToken(this.loginResponse.token)
      useJwt.setUserData(JSON.stringify(this.loginResponse.user))
      useJwt.setStoreData(JSON.stringify(store))
      if (this.loginResponse.user.permissions.length == 1 && this.loginResponse.user.permissions[0] == "show orders shipping zones") {
        window.location.href = '/shipping-zones/show-orders';
      } else {
        window.location.href = '/';
      }
    },
    resetPasswordSubmit() {
      this.isLoading = true;
      // this.$store.dispatch('login', this.form)
      useJwt.post('/forget-password',this.resetPassword)
      .then(response => {
        this.$root.showAlert('success', this.$t('Reset password email sent successfully'))
        this.isLoading = false;
      })
      .catch(error => {
        this.isLoading = false;
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
